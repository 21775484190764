@font-face {
  font-family: "Kindentosca";
  src: url("./Kindentosca.otf");
}
@font-face {
  font-family: "RobotoMono";
  src: url("./RobotoMono.ttf");
}
@font-face {
  font-family: "Flow";
  src: url("./Flow.otf");
}
@font-face {
  font-family: "Baystar";
  src: url("./Baystar.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("./Inter-VariableFont_slnt\,wght.ttf");
}
.App {
  text-align: center;
  max-width: 700px;
}

.App-logo {
  width: 90%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: move-right 2s linear;
  }
}

.App-header {
  background-color: #f5b401;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-size: calc(10px + 2vmin);
  // color: white;
  z-index: 2;
}
.image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
img {
  z-index: 2;
}
.title {
  font-family: "Kindentosca";
  font-size: 50px;
  z-index: 10;
  // margin-top: -50px;
  position: absolute;
}

@keyframes move-right {
  from {
    transform: translate(-100px, 0);
  }
  to {
    transform: translate(0px, 0);
  }
}
.date {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "RobotoMono";
  text-transform: uppercase;
  font-weight: 450;
  line-height: 100%;
  font-size: 30px;
  margin-top: 10px;
  .year {
    display: flex;
    flex-direction: column;
    // margin: 0;
    line-height: 94%;
    font-size: 16px;
    font-weight: 900;
  }
  z-index: 2;
}

.week {
  display: flex;
  gap: 10px;
  .day {
    // width: 50px;
    // background-color: aquamarine;
    font-family: "Flow";
    .Dname {
      width: 40px;
      height: 60px;
      font-size: 20px;
      // background-color: aquamarine;
      overflow-wrap: break-word;
    }
  }
  z-index: 2;

  .heart {
    position: absolute;
    margin-left: 300px;
    height: 40px;
    margin-top: 50px;
    z-index: 2;
    animation: pulse infinite linear 5s;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  gap: 20px;
  width: 90%;
  z-index: 2;
  align-items: center;
}
.titleCon {
  font-weight: 500;
  z-index: 100;
}
.couple {
  z-index: 100;
  font-size: 28px;
  font-weight: 700;
  // letter-spacing: 6px;
  span{
    font-family: 'Flow';
  }
}
.paragraph {
  font-size: 16px;
}
.adress {
  font-weight: 600;
    text-decoration: none;
    color: #000000;
}
.timer {
  font-weight: 700;
  z-index: 10;
  font-size: 50px;
  background-color: #f8f8f8a1;
  width: 100%;
  margin-top: 0px;
  h6{
    color: #3c3d3dd2;
    margin-bottom: -20px;
    // font-family: 'Flow';
    // font-family: 'Inter';
    font-family: 'Baystar';
  }
}
.dhms{
  display: flex;
  font-size: 16px;
  justify-content: center;
  gap: 25px;
  margin-top: -10px;
  p{
    width: 50px;
    // background-color: aquamarine;
  }
}
.leaf {
  width: 250px;
  z-index: 1;
  // margin-top: -230px;
  margin-top: -210px;
  position: static;
  transform: rotate(232deg);
}
.guestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  justify-content: center;
  z-index: 2000;
  p {
    font-size: 30px;
    color: #04565c;
    @media (max-width: 700px) {
      font-size: 24px;
    }
  }
  .name[type="text"] {
    margin-top: 15px;
    font-size: 28px;
    text-align: center;
    padding: 10px 0;
    width: 60%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #016b3a;
    color: #016b3a;
    font-family: "Baystar";
    width: 100%;
    @media (max-width: 700px) {
      font-size: 22px;
    }
  }
}
input[type=text]:focus {
  border: none;
    border-bottom: 2px solid #016b3a;
}
.count {
  font-family: "Inter";
  font-family: "Kindentosca";
  margin-top: 15px;
  // font-size: 50px;
  font-weight: 900;
  text-align: center;
  padding: 5px 0;
  width: 60%;
  background-color: transparent;
    font-size: 27px;
    border: none;
  border-bottom: 2px solid #016b3a;
  color: #016b3a;
  width: 100%;
  @media (max-width: 700px) {
    font-size: 27px;
  }
}
.count::placeholder {
  font-family: "Baystar";
    font-size: 27px;
  font-weight: 400;
}

.button-89 {
  --b: 3px; /* border thickness */
  --s: 0.45em; /* size of the corner */
  --color: #02553c;

  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #0000 90deg,
      var(--color) 0
    )
    var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
    calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.6em;
  font-size: 26px;
  @media (max-width: 700px) {
    font-size: 22px;
  }
  border: 0;
  margin-top: 40px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

.translate{
  position: absolute;
  cursor: pointer;
  border-bottom: #02442e 1px solid;
  font-size: large;
  margin-left: 600px;
  @media (max-width: 700px) {
  right: 0;
  margin: 0;
  }
  color: #02442e;
  border-radius: 100%;
  font-family: 'Inter';
  a{
    text-decoration: none;
    color: #02442e;

  }
}

.error {
  border: 1px solid red;
  color: red;
}


.swiper {
  // background-color: aquamarine;
  width: 100%;
  @media (max-width: 700px) {
    width: 100vw;
    }
}
.slide {
  // background-color: green;
  width: 100%;
  // @media (max-width: 700px) {
  //   width: 80%;
  //   }
}
.photo {
  width: 90%;
  // @media (max-width: 700px) {
  //   width: 80%;
  //   }
  // height: 90vh;
  // margin-left: -50px;
  // margin-top: -50px;
}
.leaf_left{
width: 70px;
position: absolute;
margin-left: -550px ;
@media (max-width: 700px) {
margin-left: 0 ;
left: 10px;
z-index: 0;
}
}
.leaf_right{
  width: 70px;
  position: absolute;
  margin-right: -550px ;
  @media (max-width: 700px) {
    margin-right: 0 ;
    right: 10px;
    z-index: 0;
    }
  }

  .parents{
    margin-top: 10px;
    font-family: "Inter";
    font-weight: 500;
    background-color:#02442eda;
    padding: 10px 0;
    width: 700px;
    color: #f8f8f8;
    @media (max-width: 700px) {
      width: 100vw;

      }
    span{
      font-size: 26px;
    font-weight: 600;

    }
  }
